@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

@font-face {
  font-family: "Museo", sans-serif;
  src: url("./fonts/Museo/museo300-regular-webfont.woff2") format("woff2"),
    url("./fonts/Museo/museo300-regular-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Museo", sans-serif;
  src: url("./fonts/Museo/museo700-regular-webfont.woff2") format("woff2"),
    url("./fonts/Museo/museo700-regular-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Museo", sans-serif;
  src: url("./fonts/Museo/museo900-regular-webfont.woff2") format("woff2"),
    url("./fonts/Museo/museo900-regular-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

a {
  text-decoration: none;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  color: "#000" !important;
}
